import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { dateConverter } from "../components/dateConverter"
import Tag from "../assets/tag.inline.svg"
import Calendar from "../assets/calendar.inline.svg"
import Glasses from "../assets/glasses.inline.svg"
import * as styles from "../styles/article.module.css"
import LanguageSwitch from "../components/languageSwitch"
import FontSizer from "../components/fontSizer"
import ThemeSwitch from "../components/themeSwitch"
import SoundButton from "../components/soundToggle"
import {
  ListTLink,
  transitionFadeOut,
  transitionFadeIn,
} from "../components/layout"
import ScrollTopArrow from "../components/backToTop"
import ArticleFeaturedImage from "../components/articleFeaturedImage"

export default function Template({
  location,
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html, fields } = markdownRemark
  const { t } = useTranslation()
  const readingTime = parseFloat(fields.readingTime.minutes).toFixed(0)

  let articleIndex
  if (location.pathname.includes("/en/")) {
    articleIndex = "/en/artikler/"
  } else {
    articleIndex = "/artikler/"
  }

  const date = dateConverter(frontmatter.date, location.pathname)
  const updateDate = dateConverter(frontmatter.updated, location.pathname)

  const articleTag = frontmatter.tags.map(tag => (
    <>
      <span className="tagBox" style={{ display: "inline-flex" }}>
        <Tag className={styles.tag} width="0.75em" height="1.25em" />
        <p className="bigLabel">&nbsp;</p>
        <p className="bigLabel">{tag}</p>
        <p className="bigLabel">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
      </span>
    </>
  ))

  return (
    <>
      <Seo
        title={frontmatter.seo_title}
        pathName={location.pathname}
        image={frontmatter.cover_image}
        date={frontmatter.published}
        description={frontmatter.seo_description}
        published={frontmatter.date}
        tags={frontmatter.tags}
        canonical={frontmatter.canonical_url}
        author={frontmatter.author}
        category={frontmatter.category}
        readingTime={readingTime}
      />
      <div id="articleP3" className="bodyWrapper">
        <LanguageSwitch />
        <SoundButton />
        <FontSizer />
        <ThemeSwitch />
        <main className="article-container">
          <article className="article">
            <h1 style={{ textAlign: "center" }} className="Display1">
              {frontmatter.title}
            </h1>
            <ArticleFeaturedImage
              style={{
                display: "block",
                maxWidth: "1000px",
                margin: "0 auto 3rem auto",
                borderRadius: "15px",
              }}
              alt={frontmatter.featured_image_alt}
              fileName={frontmatter.featured_image}
            />
            <span
              style={{
                display: "flex",
                justifyContent: "flex-start",
                flexFlow: "row wrap",
                alignItems: "center",
              }}
            >
              <Calendar className={styles.calendar} height="1em" />
              &nbsp;<p className="bigLabel">{date}</p>
              {date !== updateDate && (
                <p
                  className="bigLabel"
                  style={{ color: "var(--flirt-FLIRT_400)" }}
                >
                  &nbsp;{t("UPDATED")}:&nbsp;{updateDate}
                </p>
              )}
            </span>
            <span
              style={{
                display: "flex",
                justifyContent: "flex-start",
                flexFlow: "row wrap",
                alignItems: "center",
              }}
            >
              <Glasses className={styles.glasses} height="1em" />
              &nbsp;<p className="bigLabel">{t("READING_TIME")}:</p>
              <p style={{ margin: "0" }}>&nbsp;{readingTime}&nbsp;min.</p>
            </span>
            <span
              style={{
                display: "flex",
                justifyContent: "flex-end",
                flexFlow: "row wrap",
              }}
            >
              {articleTag}
            </span>
            <p className="article-intro" style={{ fontStyle: "italic" }}>
              {frontmatter.description}
            </p>
            <div
              className="article-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </article>
        </main>
        <nav style={{ overflowX: "visible" }} class="card wide">
          <hr></hr>
          <ScrollTopArrow showBelow={800} />
          <div style={{ width: "fit-content", margin: "0 auto" }}>
            <ListTLink
              to={articleIndex}
              exit={{
                length: 0.4,
                trigger: ({ exit, e, node }) => transitionFadeOut(exit, node),
              }}
              entry={{
                delay: 0.4,
                appearAfter: 0.4,
                length: 0.4,
                trigger: ({ entry, node }) => transitionFadeIn(node),
              }}
            >
              <h3 style={{ textAlign: "center" }}>
                {t("RETURN_TO_ARTICLE_INDEX")}
              </h3>
            </ListTLink>
          </div>
        </nav>
      </div>
    </>
  )
}
export const pageQuery = graphql`
  query ($slug: String!, $language: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MM DD YYYY")
        updated(formatString: "MM DD YYYY")
        published
        description
        tags
        canonical_url
        slug
        title
        featured_image
        featured_image_alt
        seo_image
        author
        seo_title
        seo_description
      }
      fields {
        readingTime {
          minutes
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
