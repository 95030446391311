//convert date in format MM DD YYYY
export function dateConverter(date, locationPathname) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear()

  const monthList_da = {
    1: "januar",
    2: "februar",
    3: "marts",
    4: "april",
    5: "maj",
    6: "juni",
    7: "juli",
    8: "august",
    9: "september",
    10: "oktober",
    11: "november",
    12: "december",
  }

  const monthList_en = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "ovember",
    12: "December",
  }

  const reCara = new RegExp(Object.keys(monthList_en).join("|"), "gi")

  if (locationPathname.includes("/en")) {
    month = month.replace(reCara, function (matched) {
      return monthList_en[matched]
    })
  } else {
    month = month.replace(reCara, function (matched) {
      return monthList_da[matched]
    })
  }

  if (day.length < 2) day = "0" + day
  if (locationPathname.includes("/en")) {
    if (day === "1") {
      day = "1st"
    }
    if (day === "2") {
      day = "2nd"
    }
    if (day === "3") {
      day = "3rd"
    }
    if (parseFloat(day) > 3) {
      day = day + "th"
    }
  }

  if (locationPathname.includes("/en")) {
    return month + " " + day + ", " + year
  } else return day + ". " + month + " " + year
}
