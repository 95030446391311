import React, { useContext } from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import useSound from "use-sound"
import ButtonClick from "../sounds/button-click.mp3"
import { GlobalContext } from "../components/soundToggle.js"

const FontSizer = () => {
  const { t } = useTranslation()
  const useSoundEnabled = useContext(GlobalContext)
  const soundEnabled = useSoundEnabled.soundEnabledStatus
  let [play] = useSound(ButtonClick, {
    volume: 0.25,
    soundEnabled: soundEnabled,
  })

  const decreaseFontSize = () => {
    play()
    let p2 = document.getElementById("articleP2")
    let p3 = document.getElementById("articleP3")
    let p4 = document.getElementById("articleP4")
    let p5 = document.getElementById("articleP5")
    let p6 = document.getElementById("articleP6")
    let p7 = document.getElementById("articleP7")

    if (typeof p7 !== undefined && p7 != null) {
      p7.id = "articleP6"
    } else if (typeof p6 !== undefined && p6 != null) {
      p6.id = "articleP5"
    } else if (typeof p5 !== undefined && p5 != null) {
      p5.id = "articleP4"
    } else if (typeof p4 !== undefined && p4 != null) {
      p4.id = "articleP3"
    } else if (typeof p3 !== undefined && p3 != null) {
      p3.id = "articleP2"
    } else if (typeof p2 !== undefined && p2 != null) {
      p2.id = "articleP1"
    }
  }
  const increaseFontSize = () => {
    play()
    let p1 = document.getElementById("articleP1")
    let p2 = document.getElementById("articleP2")
    let p3 = document.getElementById("articleP3")
    let p4 = document.getElementById("articleP4")
    let p5 = document.getElementById("articleP5")
    let p6 = document.getElementById("articleP6")

    if (typeof p6 !== undefined && p6 != null) {
      p6.id = "articleP7"
    } else if (typeof p5 !== undefined && p5 != null) {
      p5.id = "articleP6"
    } else if (typeof p4 !== undefined && p4 != null) {
      p4.id = "articleP5"
    } else if (typeof p3 !== undefined && p3 != null) {
      p3.id = "articleP4"
    } else if (typeof p2 !== undefined && p2 != null) {
      p2.id = "articleP3"
    } else if (typeof p1 !== undefined && p1 != null) {
      p1.id = "articleP2"
    }
  }

  return (
    <div className="fontSizer">
      <button
        title={t("DECREASE_FONT_SIZE")}
        aria-label={t("DECREASE_FONT_SIZE")}
        className="fontButton"
        onClick={decreaseFontSize}
      >
        <p style={{ fontSize: "20px" }}>Aa</p>
      </button>
      <button
        title={t("INCREASE_FONT_SIZE")}
        aria-label={t("INCREASE_FONT_SIZE")}
        className="fontButton"
        onClick={increaseFontSize}
      >
        <p style={{ fontSize: "30px" }}>Aa</p>
      </button>
    </div>
  )
}

export default FontSizer
