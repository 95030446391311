import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ArticleFeaturedImage = ({ fileName, alt, style }) => {
  const allImageSharp = useStaticQuery(graphql`
    {
      allFile(filter: { sourceInstanceName: { eq: "article_images" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(
                width: 1000
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
            name
          }
        }
      }
    }
  `)

  const fixed = allImageSharp.allFile.edges.find(
    edge => edge.node.name === fileName
  ).node

  const image = getImage(fixed)

  return (
    <>
      <GatsbyImage style={style} image={image} alt={alt} />
    </>
  )
}

export default ArticleFeaturedImage
